




const Loading = () => {


    return (
<div style={{margin:0,height:"100vh",display:"flex",alignItems:"center",justifyContent:"center",backgroundColor:"#f4f5f7"}}>
        <div className="loaderr">
            <div className="facee">
                <div className="circlee"></div>
            </div>
            <div className="facee">
                <div className="circlee"></div>
            </div>
        </div>
</div>
    )
}

export default Loading