import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './context/AuthContext'
import {HomeContextProvider} from "./context/HomeContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

        <AuthContextProvider>
            <HomeContextProvider>
                <App />
            </HomeContextProvider>
        </AuthContextProvider>

);